/**
@typedef {(ev: Event) => void} Handler
*/

const handlers = /** @type {Map<string, Handler>} */ (new Map())

/**
@param {string} name
@param {Handler} handler
@returns {void}
*/
export function createHandler(name, handler) {
  if (handlers.has(name)) {
    throw new Error(`handler ${name} already exists`)
  }

  handlers.set(name, handler)
}

function attachHandlers() {
  document
    .querySelectorAll('[data-on]')
    .forEach((/** @type {HTMLElement} */ element) => {
      const handlers = parseHandlers(element.dataset.on)
      delete element.dataset.on

      handlers.forEach(([event, handlerName]) => {
        try {
          if (event === 'load') {
            callHandler.call(element, handlerName)
            return
          }

          element.addEventListener(event, function (ev) {
            return callHandler.call(element, handlerName, ev)
          })
        } catch (err) {
          console.error(err)
        }
      })
    })
}

/**
@param {string} handlerName
@param {Event} ev
@returns {void}
*/
function callHandler(handlerName, ev) {
  const handler = handlers.get(handlerName)
  if (handler == null) {
    console.error(`unknown event handler ${handlerName}`)
    return
  }
  return handler.call(this, ev)
}

/**
@param {string} handlers
@returns {[string, string][]}
*/
function parseHandlers(handlers) {
  try {
    return Object.entries(JSON.parse(handlers))
  } catch (err) {
    return handlers.split(/\s/).map((mapping) => mapping.split(':'))
  }
}

const observer = new MutationObserver(attachHandlers)
observer.observe(document.documentElement, {
  subtree: true,
  childList: true,
  attributes: true,
})

document.addEventListener('DOMContentLoaded', attachHandlers)
